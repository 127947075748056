
import {
    LockOutlined,
    KeyOutlined,
    MailOutlined,
    SafetyCertificateOutlined
} from '@ant-design/icons-vue';
import validate from 'ele-admin-pro/packages/validate';

export default {
    name: 'Register',
    components: {
        LockOutlined,
        KeyOutlined,
        MailOutlined,
        SafetyCertificateOutlined
    },
    data() {
        return {
            // 登录框方向, 0居中, 1居右, 2居左
            direction: 0,
            // 加载状态
            loading: false,
            // 表单数据
            form: {
                account: '',
                password: '',
                password2: '',
                verifycode: ''
            },
            // 表单验证规则
            rules: {
                account: [{
                    required: true,
                    message: this.$t('share.require'),
                    type: 'string',
                    trigger: 'blur'
                }],
                password: [{
                    required: true,
                    message: this.$t('user.tips.password'),
                    type: 'string',
                    trigger: 'blur'
                }],
                password2: [{
                    required: true,
                    validator: async (rule, value) => {
                        if (!value) {
                            return Promise.reject(this.$t('user.tips.password2'));
                        }
                        if (value === this.form.password) {
                            return Promise.resolve();
                        }
                        return Promise.reject(this.$t('user.tips.error_password'));
                    },
                    trigger: 'blur'
                }],
                code: [{
                    required: true,
                    message: this.$t('user.tips.verify_code'),
                    type: 'string',
                    trigger: 'blur'
                }],
                email: [{
                    required: true,
                    validator: async ()=>{
                        if(!this.form.account){
                            return Promise.reject(this.$t('share.require'));
                        }
                        if(!validate.isEmail(this.form.account)){
                            return Promise.reject(this.$t('user.tips.wrong_email'));
                        }
                        
                        return Promise.resolve();
                    },
                    trigger: 'blur'
                }]
            },
            // 是否显示图形验证码弹窗
            showImgCode: false,
            // 验证码base64数据
            captcha: '',
            // 图形验证码
            imgCode: '',
            // 发送验证码按钮loading
            codeLoading: false,
            // 验证码倒计时时间
            countdownTime: 30,
            // 验证码倒计时定时器
            countdownTimer: null
        };
    },
    methods: {
        /**
         * 提交
         */
        doSubmit() {
            this.$refs.form.validate().then(() => {
                this.loading = true;

                this.$http.post('/passport/register', this.form).then((res) => {
                    this.loading = false;

                    if (res.data.code === 0) {
                        this.$message.success(res.data.msg);
                        this.$router.push('/login');
                    } else {
                        this.$message.error(res.data.msg);
                    }
                }).catch((e) => {
                    this.loading = false;
                    this.$message.error(e.message || 'share.request_error');
                });
            }).catch(() => {});
        },
        /**
         * 更换图形验证码
         */
        changeImgCode() {
            this.$http.get('/passport/captcha').then(res => {
                if (res.data.code === 0) {
                    this.captcha = res.data.data.image;
                    this.imgCode = res.data.data.text;
                } else {
                    this.$message.error(res.data.msg);
                }
            }).catch(e => {
                this.$message.error(e.message);
            });
        },
        /**
         * 显示发送短信验证码弹窗
         */
        showImgCodeCheck() {
            if (!this.form.account) {
                return;
            }
            this.imgCode = '';
            this.changeImgCode();
            this.showImgCode = true;
        },
        /**
         * 发送验证码
         */
        sendCode() {
            if (!this.imgCode) {
                this.$message.error('user.tips.image_code');
                return;
            }

            this.codeLoading = true;

            var body = {
                account: this.form.account,
                verifycode: this.imgCode,
                flags: 0
            };

            this.$http.post('/passport/send_verify_code', body).then(res => {
                this.codeLoading = false;

                if (res.data.code === 0) {
                    this.$message.success(res.data.msg);
                    this.showImgCode = false;
                    this.startCountdownTimer();
                } else {
                    if(res.data.fields){
                        let obj = {} 
                        res.data.fields.forEach(f => {
                            obj[f.field] =  Object.assign(this.rules[f.field], f);
                        })

                        Object.assign(this.rules, obj);
                        this.$refs.form.validate()
                    }
                    this.imgCode = '';
                    this.changeImgCode();
                    this.$message.error(res.data.msg);
                }
            }).catch(e => {
                this.showImgCode = false;
                this.codeLoading = false;

                this.$message.error(e.message);
            });
        },
        /**
         * 开始对按钮进行倒计时
         */
        startCountdownTimer() {
            this.countdownTime = 60;
            this.countdownTimer = setInterval(() => {
                if (this.countdownTime <= 1) {
                    clearInterval(this.countdownTimer);
                    this.countdownTimer = null;
                }
                this.countdownTime--;
            }, 1000);
        }
    },
    unmounted() {
        if (this.countdownTimer) {
            clearInterval(this.countdownTimer);
        }
    }
}
